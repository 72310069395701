/*

  A17

  Doc: // Doc: https://code.area17.com/a17/fe-boilerplate/wikis/js-app

*/

// set up a master object
var A17 = window.A17 || {};

// HTML4 browser?
if (!A17.browserSpec || A17.browserSpec === 'html4') {
  // lets kill further JS execution of A17 js here
  throw new Error('HTML4');
}

// set up some objects within the master one, to hold my Helpers and behaviors
A17.Behaviors = {};
A17.Helpers = {};
A17.Functions = {};
A17.currentMediaQuery = 'large';
A17.activeBehaviors = {};

// set up and trigger looking for the behaviors on DOM ready
A17.onReady = function(){

  // sort out which media query we're using
  A17.currentMediaQuery = A17.Helpers.getCurrentMediaQuery();

  // go go go
  A17.Helpers.manageBehaviors();

  // on resize, check
  A17.Helpers.resized();
};

document.addEventListener('DOMContentLoaded', function(){
  A17.onReady();
});

// make console.log safe
if (typeof console === 'undefined') {
  window.console = {
    log: function () {
      return;
    }
  };
}

A17.Helpers.getCurrentMediaQuery = function() {
  // Doc: https://code.area17.com/mike/a17-js-helpers/wikis/A17-Helpers-getCurrentMediaQuery

  function parse(str) {
    return str.replace(/'/gi, '').replace(/"/gi, '');
  }

  if (window.opera) {
    return parse(window.getComputedStyle(document.body, ':after').getPropertyValue('content')) || 'large';
  } else if (window.getComputedStyle) {
    return parse(window.getComputedStyle(document.head, null).getPropertyValue('font-family')) || 'large';
  } else {
    return 'large';
  }
};

A17.Helpers.manageBehaviors = function(options) {

  /*
    default init listens for event 'page:updated':
    A17.Helpers.manageBehaviors();

    init for pjax:
    A17.Helpers.manageBehaviors({pageUpdatedEventName:'pjax:end'});

    init for spf:
    A17.Helpers.manageBehaviors({pageUpdatedEventName:'spfdone'});
  */

  var idCounter = 0;
  var pageUpdatedEventName = (options && options.pageUpdatedEventName) ? options.pageUpdatedEventName : 'page:updated';

  function searchDomAndInitBehaviors(context) {
    if(context === undefined) {
      context = document;
    }
    var all = context.querySelectorAll('[data-behavior]');
    var i = -1;
    while (all[++i]) {
      var currentElement = all[i];

      // check to see if this element has had its behaviors already initialized by looking for _A17BehaviorsActive
      if (!currentElement._A17BehaviorsActive) {
        //console.log('initializing behaviors for:\n', currentElement);
        var behaviors = currentElement.getAttribute('data-behavior');
        var splittedBehaviors = behaviors.split(' ');
        for (var j = 0, k = splittedBehaviors.length; j < k; j++) {
          var ThisBehavior = A17.Behaviors[splittedBehaviors[j]];
          if(typeof ThisBehavior !== 'undefined') {
            try {
              // mark the element as having its behaviors initialized
              currentElement._A17BehaviorsActive = true;

              // add this instance to the activeBehaviors object so it can be interrogated if the page is updated later
              A17.activeBehaviors[idCounter] = {
                el: currentElement,
                behavior: new ThisBehavior(currentElement),
                name: splittedBehaviors[j]
              };

              try {
                A17.activeBehaviors[idCounter].behavior.init();
              } catch (err) {
                //console.log('failed to init behavior: ', A17.activeBehaviors[idCounter].name, '\n', err, A17.activeBehaviors[idCounter]);
              }

              idCounter++;
            } catch(err) {
              //console.log(err, currentElement, ThisBehavior);
            }
          }
        }
      } else {

        //console.log('behaviors already initialized for:\n', currentElement);
      }
    }
  }

  function pageUpdated() {
    // first check if anything was removed and clean up
    for (var activeBehaviorObj in A17.activeBehaviors) {
      if (A17.activeBehaviors.hasOwnProperty(activeBehaviorObj)) {
        var thisBehaviorObj = A17.activeBehaviors[activeBehaviorObj];

        // check if the element is still there
        if (!document.body.contains(thisBehaviorObj.el)) {
          //console.log('element no longer exists:\n', thisBehaviorObj.name, thisBehaviorObj);

          // trigger its destroy if its gone
          try {
            thisBehaviorObj.behavior.destroy();
            delete A17.activeBehaviors[activeBehaviorObj];
          } catch (err) {
            //console.log('failed to destroy behavior: ', thisBehaviorObj.name, '\n', err, thisBehaviorObj);
          }
        } else {

          //console.log('element still exists:\n', thisBehaviorObj.name, thisBehaviorObj);
        }
      }
    }

    // now look for new behaviors!
    searchDomAndInitBehaviors();
  }

  searchDomAndInitBehaviors();
  document.addEventListener(pageUpdatedEventName, pageUpdated);
  document.addEventListener('content:updated', function() {
    searchDomAndInitBehaviors( (event.data.el) ? event.data.el : '' );
  });
};

A17.Helpers.purgeProperties = function(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      delete obj[prop];
    }
  }
};

A17.Helpers.resized = function() {
  // Doc: https://code.area17.com/mike/a17-js-helpers/wikis/A17-Helpers-resized

  var resizeTimer;
  var mediaQueryUpdated = false;

  window.addEventListener('resize', function() {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
      // check media query
      var newMediaQuery = A17.Helpers.getCurrentMediaQuery();

      // is it different? if so, update global var
      if (A17.currentMediaQuery !== newMediaQuery) {
        A17.currentMediaQuery = newMediaQuery;
        mediaQueryUpdated = true;
      }

      // tell everything resized happened
      A17.Helpers.triggerCustomEvent(document, 'resized');

      // if media query changed, tell everything
      if (mediaQueryUpdated) {
        A17.Helpers.triggerCustomEvent(document, 'mediaQueryUpdated');
      }
    }, 250);
  });

};

A17.Helpers.triggerCustomEvent = function(el, type, data) {
  // Doc: https://code.area17.com/mike/a17-js-helpers/wikis/A17-Helpers-triggerCustomEvent

  var event = document.createEvent('HTMLEvents');
  event.initEvent(type, true, true);
  event.data = data || {};
  event.eventName = type;
  event.target = el;
  el.dispatchEvent(event);
};

A17.Behaviors.DummyBehavior = function(container) {

  function _handleClicks() {
    // action
  }

  function _handleResized() {
    // action
  }

  function _init() {
    container.addEventListener('click', _handleClicks, false);
    document.addEventListener('resized', _handleResized);
  }

  this.destroy = function() {
    // remove specific event handlers
    container.removeEventListener('click', _handleClicks);
    document.removeEventListener('resized', _handleResized);

    // remove properties of this behavior
    A17.Helpers.purgeProperties(this);
  };

  this.init = function() {
    _init();
  };
};
